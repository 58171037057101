import Header from "../../layouts/Header";
import Footer from "../../layouts/FooterPages";
const SecTerms = ({ data }) => {
  return (
    <>
      <Header />
      <section
        className="trust-section section-padding not-copy section-margin-100 p-3"
        style={{ margin: "auto", maxWidth: "1024px", color: "#FFFFFF" }}
      >
        <div className="section-heading ">
          <h2 className="text-center" style={{ color: "#FFFFFF" }}>
            AnonyDoxx Terms of Service
          </h2>
          <h6 className="text-center" style={{ color: "#FFFFFF" }}>
            Posted: June 22, 2022
          </h6>
          <h6 className="text-center" style={{ color: "#FFFFFF" }}>
            Effective: June 22, 2022
          </h6>
          <p className="policy_text py-3">
            Thanks for using AnonyDoxx. Our mission is to make the internet a
            safer, inclusive and more trustworthy place. These terms of service
            (“<b>Terms</b>”) cover your use and access to our services, software
            and websites (“<b>Services</b>”). Our{" "}
            <b>
              <u>Privacy Policy</u>
            </b>{" "}
            explains how we collect and use your information while our{" "}
            <b>
              <u>Acceptable Use Policy</u>
            </b>{" "}
            outlines your responsibilities when using our Services. By using our
            Services, you are agreeing to be bound by these Terms, our{" "}
            <b>
              <u>Privacy Policy</u>
            </b>{" "}
            and our{" "}
            <b>
              <u>Acceptable Use Policy</u>
            </b>
            .
          </p>
          <h5 className="policy_text py-3">
            <b>
              <u> Your Stuff & Your Permissions</u>
            </b>
          </h5>
          <p className="policy_text pl-4  py-1">
            When you submit, upload, transmit or provide us with any data,
            information, media or other content in connection with your use of
            our Services (“<b>Your Stuff</b>”), you understand and agree that:
          </p>
          <ul className="pl-5">
            <li style={{ listStyleType: "disc", color: "#FFFFFF" }}>
              {" "}
              you will continue to own and be responsible for Your Stuff;
            </li>
            <li style={{ listStyleType: "disc", color: "#FFFFFF" }}>
              {" "}
              we will not sell Your Stuff to any third party;
            </li>
            <li style={{ listStyleType: "disc", color: "#FFFFFF" }}>
              {" "}
              you are giving us and our affiliate companies a perpetual,
              non-exclusive, transferable, sub-licensable, royalty-free,
              worldwide license to use Your Stuff (with no fees or charges
              payable by us to you) for the purposes of providing, promoting,
              developing and trying to improve AnonyDoxx, the Services and any
              new services that we may provide in the future. All such use will,
              to the extent Your Stuff contains personal information, be in
              accordance with our{" "}
              <b>
                <u>Privacy Policy</u>
              </b>
              . As part of this license, we and our affiliate companies may,
              subject to the{" "}
              <b>
                <u>Privacy Policy</u>
              </b>
              , copy, reproduce, host, store, process, adapt, modify, translate,
              perform, distribute and publish Your Stuff worldwide in all media
              and by all distribution methods, including those that are
              developed in the future;{" "}
            </li>
            <li style={{ listStyleType: "disc", color: "#FFFFFF" }}>
              {" "}
              you grant other AnonyDoxx users a non-exclusive license to access
              and use Your Stuff within AnonyDoxx, in accordance with these
              Terms and AnonyDoxx’s functionalities;
            </li>
            <li style={{ listStyleType: "disc", color: "#FFFFFF" }}>
              we may share Your Stuff with third parties that we work with to
              help provide, promote, develop and improve AnonyDoxx in accordance
              with the{" "}
              <b>
                <u>Privacy Policy</u>
              </b>
              ;
            </li>
            <li style={{ listStyleType: "disc", color: "#FFFFFF" }}>
              billing or financial information, including payment card
              information;
            </li>
            <li style={{ listStyleType: "disc", color: "#FFFFFF" }}>
              we may use the name that you submit in connection with Your Stuff
              (whether that be your account name, real name or otherwise); and
            </li>
            <li style={{ listStyleType: "disc", color: "#FFFFFF" }}>
              you will comply with these Terms, including our{" "}
              <b>
                <u>Acceptable Use Policy</u>
              </b>
              , in your submission of Your Stuff and your use of the Services.
            </li>
          </ul>

          <p className="policy_text pl-4  py-1">
            In addition, you agree that we and our affiliate companies (subject
            to these Terms, our{" "}
            <b>
              <u>Privacy Policy</u>
            </b>{" "}
            and applicable laws and regulations):
          </p>
          <ul className="pl-5">
            <li style={{ listStyleType: "disc", color: "#FFFFFF" }}>
              {" "}
              are allowed to retain and continue to use Your Stuff after you
              stop using AnonyDoxx;
            </li>
            <li style={{ listStyleType: "disc", color: "#FFFFFF" }}>
              {" "}
              may be required to retain or disclose Your Stuff: (i) in order to
              comply with applicable laws or regulations; (ii) in order to
              comply with a court order, subpoena or other legal process; (iii)
              in order to respond to a lawful request by a government authority,
              law enforcement agency or similar body; or (iv) where we believe
              it is reasonably necessary to comply with applicable laws or
              regulations, in each case including (where applicable) orders,
              requests and other legal processes issued or made pursuant to
              mutual legal assistance treaty or similar lawful means. You may
              refer to our{" "}
              <b>
                <u>Law Enforcement Data Request Guidelines</u>
              </b>{" "}
              for further details on how we may retain or disclose Your Stuff;
            </li>
            <li style={{ listStyleType: "disc", color: "#FFFFFF" }}>
              may be required to retain or disclose Your Stuff in order to
              enforce these Terms or to protect any rights, property or safety
              of ours, our affiliate companies or other users of the Services.
            </li>
          </ul>
          <h5 className="policy_text py-3">
            <b>
              <u> Your Responsibilities</u>
            </b>
          </h5>
          <p className="policy_text pl-4  py-1">
            Your use of our Services must comply with our{" "}
            <b>
              <u>Acceptable Use Policy</u>
            </b>{" "}
            Content in the Services may be protected by others’ intellectual
            property rights. Please do not copy, download or share any content
            made available to you in connection with the Services unless you
            have the right to do so.{" "}
          </p>
          <p className="policy_text pl-4  py-1">
            Help us keep Your Stuff protected. Safeguard your password to the
            Services, and keep your account information current. Do not share
            your account credentials or give others access to your account.{" "}
          </p>
          <p className="policy_text pl-4  py-1">
            You may use our Services only as permitted by applicable law,
            including export control laws and regulations. Finally, to use our
            Services, you must be at least 13 if you reside in the United
            States, and 16 if you reside anywhere else. However, to participate
            in our Vault Verification protocol, you must be at least 18. If the
            law where you reside requires that you must be older in order for
            AnonyDoxx to lawfully provide the Services to you without parental
            consent (including use of your personal data), then you must be that
            older age.
          </p>
          <h5 className="policy_text py-3">
            <b>
              <u>Software</u>
            </b>
          </h5>
          <p className="policy_text pl-4  py-1">
            Some of our Services may allow you to download certain software (“
            <b>Software</b>”) which may update automatically. So long as you
            comply with these Terms, we give you a limited, nonexclusive,
            nontransferable, revocable license to use the Software, solely to
            access the Services. To the extent any component of the Software may
            be offered under an open-source license, we will make that license
            available to you and the provisions of that license may expressly
            override some of these Terms. Unless the following restrictions are
            prohibited by law, you agree not to reverse engineer or decompile
            the Services, attempt to do so or assist anyone in doing so.
          </p>
          <h5 className="policy_text py-3">
            <b>
              <u>Beta Services</u>
            </b>
          </h5>
          <p className="policy_text pl-4  py-1">
            We may release products and features that we are still testing and
            evaluating (“<b>Beta Services</b>”). Beta Services may be labeled
            “alpha,” “beta,” “preview,” “early access,” or “evaluation” (or with
            words or phrases with similar meanings) and may not be as reliable
            as AnonyDoxx’ s other services. Beta Services may be made available
            so that we can collect user feedback. By using any of our Beta
            Services, you agree that we may contact you to collect such
            feedback.
          </p>
          <p className="policy_text pl-4  py-1">
            Beta Services are confidential until official launch. If you use any
            Beta Services, you agree not to disclose any information about the
            Beta Services to anyone else without our permission.
          </p>
          <h5 className="policy_text py-3">
            <b>
              <u>Additional Features</u>
            </b>
          </h5>
          <p className="policy_text pl-4  py-1">
            From time to time, AnonyDoxx may add additional features to enhance
            the user experience of the Services at no additional charge.
            However, these free features may be withdrawn without further
            notice.
          </p>
          <h5 className="policy_text py-3">
            <b>
              <u>Our Stuff </u>
            </b>
          </h5>
          <p className="policy_text pl-4  py-1">
            The Services are protected by copyright, trademark and other US and
            foreign laws. These Terms do not grant you any right, title or
            interest in the Services, others’ content in the Services,
            AnonyDoxx’s trademarks, logos or other brand features. We welcome
            feedback, but note that we may use comments or suggestions without
            any obligation to you.
          </p>
          <h5 className="policy_text py-3">
            <b>
              <u>Copyright </u>
            </b>
          </h5>
          <p className="policy_text pl-4  py-1">
            We respect the intellectual property of others and ask that you do
            too. We respond to notices of alleged copyright infringement if they
            comply with the law. All such notices should be reported to us at
            security@anonydoxx.io with “Copyright Notice” in the subject line.
          </p>
          <h5 className="policy_text py-3">
            <b>
              <u>Paid Accounts </u>
            </b>
          </h5>
          <p className="policy_text pl-4  py-1">
            <i>Billing</i>. You can and add paid features to your account
            (turning your account into a “Paid Account”). We will automatically
            bill you from the date you convert to a Paid Account and on each
            periodic renewal until cancellation. You are responsible for all
            applicable taxes, which we will charge when required to do so under
            applicable law. Some countries have mandatory local laws regarding
            your cancellation rights, and this paragraph does not override such
            laws.
          </p>
          <p className="policy_text pl-4  py-1">
            <i>Cancellation</i>. You may cancel your Paid Account at any time;
            however, refunds will only be issued if required by law. For
            example, users living in the European Union have the right to cancel
            their Paid Account subscriptions within 14 days of signing up for,
            upgrading to or renewing a Paid Account by contacting us at
            security@anonydoxx.io.
          </p>
          <p className="policy_text pl-4  py-1">
            <i>Downgrades</i>. Your Paid Account will remain in effect until it
            is cancelled or terminated under these Terms. If you do not pay for
            your Paid Account on time, we reserve the right to suspend it or
            remove Paid Account features.
          </p>
          <p className="policy_text pl-4  py-1">
            <i>Changes</i>. We may change the fees in effect on renewal of your
            subscription, to reflect factors such as changes to our product
            offerings, changes to our business or changes in economic
            conditions. We will give you no less than 30 days’ advance notice of
            these changes via a message to the email address associated with
            your account and you will have the opportunity to cancel your
            subscription before the new fee comes into effect.
          </p>
          <h5 className="policy_text py-3">
            <b>
              <u>AnonyDoxx Business Accounts </u>
            </b>
          </h5>
          <p className="policy_text pl-4  py-1">
            If you sign up for an AnonyDoxx account with an email address
            provisioned by your organization, your organization may be able to
            block your use of or access to the Services until you associate your
            AnonyDoxx account with a personal email address.
          </p>
          <h5 className="policy_text py-3">
            <b>
              <u>Termination </u>
            </b>
          </h5>
          <p className="policy_text pl-4  py-1">
            You are free to stop using our Services at any time. We reserve the
            right to suspend or terminate your access to the Services with
            notice to you if:
          </p>
          <ol className="pl-5">
            <li style={{ listStyleType: "numeric", color: "#FFFFFF" }}>
              {" "}
              you are in breach of these Terms;
            </li>
            <li style={{ listStyleType: "numeric", color: "#FFFFFF" }}>
              {" "}
              your use of the Services would cause a real risk of harm or loss
              to us or other users; or
            </li>
            <li style={{ listStyleType: "numeric", color: "#FFFFFF" }}>
              {" "}
              you do not have a Paid Account and have not accessed our Services
              for 12 consecutive months.
            </li>
          </ol>
          <h5 className="policy_text py-3">
            <b>
              <u>Discontinuation of Services </u>
            </b>
          </h5>
          <p className="policy_text pl-4  py-1">
            We may decide to discontinue the Services in response to exceptional
            unforeseen circumstances, events beyond AnonyDoxx’s control (for
            example, a natural disaster, fire or explosion) or to comply with a
            legal requirement.
          </p>
          <h5 className="policy_text py-3">
            <b>
              <u>Services “AS IS” </u>
            </b>
          </h5>
          <p className="policy_text pl-4  py-1">
            We strive to provide great Services, but there are certain things
            that we cannot guarantee. TO THE FULLEST EXTENT PERMITTED BY LAW,
            ANONYDOXX AND ITS AFFILIATES, SUPPLIERS AND DISTRIBUTORS MAKE NO
            WARRANTIES, EITHER EXPRESS OR IMPLIED, ABOUT THE SERVICES. THE
            SERVICES ARE PROVIDED “AS IS”. WE ALSO DISCLAIM ANY WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
            NON-INFRINGEMENT. Some places do not allow the disclaimers in this
            paragraph, so they may not apply to you. For example, these
            disclaimers do not override the legal protections, including
            statutory warranties, granted to consumers by EU law.
          </p>
          <h5 className="policy_text py-3">
            <b>
              <u>Limitation of Liability</u>
            </b>
          </h5>
          <p className="policy_text pl-4  py-1">
            WE DO NOT EXCLUDE OR LIMIT OUR LIABILITY TO YOU WHERE IT WOULD BE
            ILLEGAL TO DO SO—THIS INCLUDES ANY LIABILITY FOR ANONYDOXX’S OR ITS
            AFFILIATES’ FRAUD OR FRAUDULENT MISREPRESENTATION IN PROVIDING THE
            SERVICES. IN COUNTRIES WHERE THE FOLLOWING TYPES OF EXCLUSIONS ARE
            NOT ALLOWED, WE ARE RESPONSIBLE TO YOU ONLY FOR LOSSES AND DAMAGES
            THAT ARE A REASONABLY FORESEEABLE RESULT OF OUR FAILURE TO USE
            REASONABLE CARE AND SKILL OR OUR BREACH OF OUR CONTRACT WITH YOU.
            THIS PARAGRAPH DOES NOT AFFECT CONSUMER RIGHTS THAT CANNOT BE WAIVED
            OR LIMITED BY ANY CONTRACT OR AGREEMENT. IF YOU ARE AN EU OR UK
            CONSUMER, THESE TERMS DO NOT EXCLUDE ANONYDOXX’S LIABILITY FOR
            LOSSES AND DAMAGES THAT ARE A RESULT OF OUR FAILURE TO USE
            REASONABLE CARE AND SKILL IN PROVIDING THE SERVICES OR OF OUR BREACH
            OF OUR CONTRACT WITH YOU, AS LONG AS THOSE LOSSES AND DAMAGES ARE
            REASONABLY FORESEEABLE.
          </p>
          <p className="policy_text pl-4  py-1">
            IN COUNTRIES WHERE EXCLUSIONS OR LIMITATIONS OF LIABILITY ARE
            ALLOWED, ANONYDOXX, ITS AFFILIATES, SUPPLIERS OR DISTRIBUTORS WILL
            NOT BE LIABLE FOR:
          </p>
          <ol className="pl-5">
            <li style={{ listStyleType: "numeric", color: "#FFFFFF" }}>
              {" "}
              ANY INDIRECT, SPECIAL INCIDENTAL, PUNITIVE, EXEMPLARY OR
              CONSEQUENTIAL DAMAGES; OR
            </li>
            <li style={{ listStyleType: "numeric", color: "#FFFFFF" }}>
              {" "}
              <p className="policy_text py-1">
                {" "}
                ANY LOSS OF USE, DATA, BUSINESS OR PROFITS, REGARDLESS OF LEGAL
                THEORY. THESE EXCLUSIONS OR LIMITATIONS WILL APPLY REGARDLESS OF
                WHETHER ANONYDOXX OR ANY OF ITS AFFILIATES HAS BEEN WARNED OF
                THE POSSIBILITY OF SUCH DAMAGES.
              </p>
              <p className="policy_text  py-1">
                IF YOU USE THE SERVICES FOR ANY COMMERCIAL, BUSINESS OR RE-SALE
                PURPOSE, ANONYDOXX, ITS AFFILIATES, SUPPLIERS OR DISTRIBUTORS
                WILL HAVE NO LIABILITY TO YOU FOR ANY LOSS OF PROFIT, LOSS OF
                BUSINESS, BUSINESS INTERRUPTION OR LOSS OF BUSINESS OPPORTUNITY.
                ANONYDOXX AND ITS AFFILIATES ARE NOT RESPONSIBLE FOR THE
                CONDUCT, WHETHER ONLINE OR OFFLINE, OF ANY USER OF THE SERVICES.
              </p>
              <p className="policy_text  py-1">
                OTHER THAN FOR THE TYPES OF LIABILITY WE CANNOT LIMIT BY LAW (AS
                DESCRIBED IN THIS SECTION), WE LIMIT OUR LIABILITY TO YOU TO THE
                GREATER OF $20 USD OR 100% OF ANY AMOUNT YOU HAVE PAID UNDER
                YOUR CURRENT SERVICE PLAN WITH ANONYDOXX. THIS PROVISION DOES
                NOT APPLY TO EU CONSUMERS WHERE PROHIBITED BY APPLICABLE LAW.
              </p>
            </li>
          </ol>
          <h5 className="policy_text py-3">
            <b>
              <u>Resolving Disputes</u>
            </b>
          </h5>
          <p className="policy_text pl-4  py-1">
            <i>Let’s Try to Sort Things Out First</i>. We want to address your
            concerns without needing a formal legal case. Before filing a claim
            against AnonyDoxx, you agree to try to resolve the dispute
            informally by sending us a written notice of dispute at
            security@anonydoxx.io that includes your name, a detailed
            description of the dispute and the relief you seek (a “Notice of
            Dispute”). We will try to resolve the dispute informally by
            contacting you via email. If a dispute is not resolved within 60
            days after submission, you or AnonyDoxx may bring a formal
            proceeding. If you reside in the EU, the European Commission
            provides for an online dispute resolution platform, which you can
            access here:{" "}
            <a href="https://ec.europa.eu/consumers/odr" target="_blank">
              https://ec.europa.eu/consumers/odr
            </a>
             .
          </p>
          <p className="policy_text pl-4  py-1">
            <i>Judicial Forum for Disputes</i>. You and AnonyDoxx agree that any
            judicial proceeding to resolve claims relating to these Terms or the
            Services will be brought in the federal or state courts located in
            Wilmington, Delaware, subject to the mandatory arbitration
            provisions below. Both you and AnonyDoxx consent to venue and
            personal jurisdiction in such courts. If you reside in a country
            (for example, a member state of the European Union) with laws that
            give consumers the right to bring disputes in their local courts,
            this paragraph does not affect those requirements.
          </p>
          <p className="policy_text pl-4  py-1">
            IF YOU ARE A U.S. RESIDENT, YOU ALSO AGREE TO THE FOLLOWING
            MANDATORY ARBITRATION PROVISIONS:
          </p>
          <p className="policy_text pl-4  py-1">
            <i>We Both Agree to Arbitrate</i>. You and AnonyDoxx agree to
            resolve any claims relating to these Terms or the Services through
            final and binding individual arbitration by a single arbitrator,
            except as set forth under the “Exceptions to Agreement to Arbitrate”
            below. This includes disputes arising out of or relating to the
            interpretation or application of this “Mandatory Arbitration
            Provisions” section, including its scope, enforceability,
            revocability or validity. The arbitrator may award relief only
            individually and only to the extent necessary to redress your
            individual claim(s); the arbitrator may not award relief on behalf
            of others or the general public.
          </p>
          <p className="policy_text pl-4  py-1">
            <i>Opt out of Agreement to Arbitrate</i>. You can decline this
            agreement to arbitrate by contacting us at security@anonydoxx.io
            with “Opt Out Request” in the subject line within 30 days of first
            registering your account or agreeing to these Terms.
          </p>
          <p className="policy_text pl-4  py-1">
            <i>Arbitration Procedures</i>. The American Arbitration Association
            (AAA) will administer the arbitration under its Consumer Arbitration
            Rules. The AAA’s rules and filing instructions are available at 
            <a href="https://www.adr.org" target={"_blank"}>
              www.adr.org
            </a>
             or by calling 1-800-778-7879. The arbitration will be held in the
            United States county where you live or work, Savannah, Georgia or
            any other location we agree to.
          </p>
          <p className="policy_text pl-4  py-1">
            <i>Arbitration Fees and Incentives</i>. The AAA rules will govern
            payment of all arbitration fees. For individual arbitration of
            non-frivolous claims less than $75,000 for which you timely provided
            AnonyDoxx with a Notice of Dispute, AnonyDoxx will reimburse
            arbitration filing fees at the conclusion of the arbitration and
            will pay other arbitration fees. For all other claims, the costs and
            fees of arbitration shall be allocated in accordance with the
            arbitration provider’s rules, including rules regarding frivolous or
            improper claims. If you receive an arbitration award that is more
            favorable than any offer we make to resolve the claim, we will pay
            you $1,000 in addition to the award. AnonyDoxx will not seek its
            attorneys’ fees and costs in arbitration unless the arbitrator
            determines that your claim is frivolous or brought for an improper
            purpose.
          </p>
          <p className="policy_text pl-4  py-1">
            <i>Exceptions to Agreement to Arbitrate</i>. Either you or AnonyDoxx
            may assert claims, if they qualify, in small claims court in
            Savannah, Georgia or any United States county where you live or
            work. Either party may bring a lawsuit solely for injunctive relief
            to stop unauthorized use or abuse of the Services or intellectual
            property infringement (for example, trademark, trade secret,
            copyright or patent rights) without first engaging in arbitration or
            the informal dispute-resolution process described above. If the
            agreement to arbitrate is found not to apply to you or your claim,
            you agree to the exclusive jurisdiction of the state and federal
            courts in Wilmington, Delaware to resolve your claim.
          </p>
          <p className="policy_text pl-4  py-1">
            <i>NO CLASS OR REPRESENTATIVE ACTIONS</i>. You may only resolve
            disputes with us on an individual basis. You may not bring a claim
            as a plaintiff or a class member in a class, consolidated or
            representative action. Class arbitrations, class actions, private
            attorney general actions and consolidation with other arbitrations
            are not allowed.
          </p>
          <p className="policy_text pl-4  py-1">
            <i>Severability</i>. You may only resolve disputes with us on an
            individual basis. You may not bring a claim as a plaintiff or a
            class member in a class, consolidated or representative action.
            Class arbitrations, class actions, private attorney general actions
            and consolidation with other arbitrations are not allowed.
          </p>
          <h5 className="p-3">
            <u>Controlling Law</u>
          </h5>
          <p className="policy_text px-4 py-1">
            These Terms will be governed by the laws of the State of Delaware
            except for its conflicts of laws principles. However, some countries
            (including those in the European Union) have laws that require
            agreements to be governed by the local laws of the consumer’s
            country. This paragraph does not override those laws.
          </p>
          <h5 className="p-3">
            <u>Entire Agreement</u>
          </h5>
          <p className="policy_text px-4 py-1">
            These Terms constitute the entire agreement between you and
            AnonyDoxx with respect to the subject matter hereof, and supersede
            and replace any other prior or contemporaneous agreements, terms or
            conditions applicable to the subject matter of these Terms. Our
            past, present and future affiliates and agents can invoke our rights
            under this agreement in the event they become involved in a dispute
            with you. Otherwise, these Terms do not give rights to any third
            parties.
          </p>
          <h5 className="p-3">
            <u> Waiver, Severability & Assignment</u>
          </h5>
          <p className="policy_text px-4 py-1">
            AnonyDoxx’s failure to enforce a provision is not a waiver of its
            right to do so later. If a provision is found unenforceable, the
            remaining provisions of the Terms will remain in full effect and an
            enforceable term will be substituted reflecting our intent as
            closely as possible. You may not assign any of your rights under
            these Terms, and any such attempt will be void. AnonyDoxx may assign
            its rights to any of its affiliates or subsidiaries, or to any
            successor in interest of any business associated with the Services.
          </p>

          <h5 className="p-3">
            <u> Modifications</u>
          </h5>
          <p className="policy_text px-4 py-1">
            We may revise these Terms from time to time to better reflect:
          </p>
          <ul className="px-5">
            <li style={{ listStyleType: "numeric" }}>changes to the law;</li>
            <li style={{ listStyleType: "numeric" }}>
              new regulatory requirements; or
            </li>
            <li style={{ listStyleType: "numeric" }}>
              improvements or enhancements made to our Services.
            </li>
          </ul>
          <p className="policy_text px-4 py-1">
            If an update affects your use of the Services or your legal rights
            as a user of our Services, we will notify you prior to the update’s
            effective date by sending an email to the email address associated
            with your account or via an in-product notification. These updated
            terms will be effective no less than 30 days from when we notify
            you.
          </p>
          <h5 className="p-3">Acceptable Use Policy</h5>
          <p className="policy_text px-4 py-1">
            AnonyDoxx was created to make the internet a safer, inclusive and
            more trustworthy place. In exchange, we trust you to use our
            services responsibility.
          </p>
          <p className="policy_text px-4 py-1">
            As a condition to using the AnonyDoxx services (“<b>Services</b>”),
            you agree not to misuse the Services or to help anyone else do so.
            For example, you must not even try to do any of the following:
          </p>
          <ul className="px-5">
            <li style={{ listStyleType: "disc" }}>
              probe, scan or test the vulnerability of any system or network,
              unless done in compliance with a bug bounty program formally
              established by AnonyDoxx;
            </li>
            <li style={{ listStyleType: "disc" }}>
              breach or otherwise circumvent any security or authentication
              measures;
            </li>
            <li style={{ listStyleType: "disc" }}>
              access, tamper with or use non-public areas or parts of the
              Services or shared areas of the Services you have not been invited
              to;
            </li>
            <li style={{ listStyleType: "disc" }}>
              interfere with or disrupt any user, host or network, for example
              by sending a virus, overloading, flooding, spamming or
              mail-bombing any part of the Services;
            </li>
            <li style={{ listStyleType: "disc" }}>
              access, search or create accounts for the Services by any means
              other than our publicly supported interfaces (for example,
              “scraping” or creating accounts in bulk);
            </li>
            <li style={{ listStyleType: "disc" }}>
              send unsolicited communications, promotions or advertisements or
              spam;
            </li>
            <li style={{ listStyleType: "disc" }}>
              send altered, deceptive or false source-identifying information,
              including “spoofing” or “phishing”;
            </li>
            <li style={{ listStyleType: "disc" }}>
              promote or advertise products or services other than your own
              without appropriate authorization;
            </li>
            <li style={{ listStyleType: "disc" }}>
              abuse referrals or promotions;
            </li>
            <li style={{ listStyleType: "disc" }}>
              sell the Services unless specifically authorized to do so;
            </li>
            <li style={{ listStyleType: "disc" }}>
              advocate bigotry or hatred against any person or group of people
              based on their race, religion, ethnicity, sex, gender identity,
              sexual orientation, disability or impairment;
            </li>
            <li style={{ listStyleType: "disc" }}>
              harass or abuse AnonyDoxx users, personnel, representatives or
              other agents performing services on behalf of AnonyDoxx;
            </li>
            <li style={{ listStyleType: "disc" }}>
              violate the law in any way, including publishing or sharing
              material that is fraudulent, defamatory or misleading or that
              violates the intellectual property rights of others; or
            </li>
            <li style={{ listStyleType: "disc" }}>
              violate the privacy or infringe the rights of others.
            </li>
          </ul>
          <p className="policy_text px-4 py-1">
            We reserve the right to take appropriate action in response to
            violations of this policy, which could include removing or disabling
            access to content, suspending a user’s access to the Services or
            terminating an account.
          </p>
          <h5 className="p-3">
            <u>
              Law Enforcement and Governmental Authority Data Request Guidelines
            </u>
          </h5>
          <h6 className=" px-4  py-1">Last Updated: June 22, 2022</h6>
          <p className="policy_text px-4 py-1">
            These operational guidelines are for any law enforcement agency or
            other government authority (the “<b>Requesting Authority</b>”)
            making a request (including in connection with any court injunction,
            court order, lawfully issued subpoena or police investigation) to
            AnonyDoxx, LLC (“<b>we</b>”, “<b>our</b>”, “<b>us</b>” or “
            <b>AnonyDoxx US</b>”) for disclosure of data about our users (a “
            <b>Request</b>”).
          </p>
          <p className="policy_text px-4 py-1">
            We respond to Requests made by a Requesting Authority in accordance
            with these guidelines, subject always to applicable laws and
            regulations. In specific cases, we may depart from these guidelines
            in our responses. We reserve the right to modify these guidelines
            where necessary and without providing notice, taking into
            consideration applicable laws and regulations and the evolving needs
            of the organization.
          </p>
          <p className="policy_text px-4 py-1">
            We provide non-public customer information in response to law
            enforcement requests when legally required to do so. We will
            scrutinize all requests to make sure they comply with the law. Where
            relevant or necessary, we may require appropriate legal and/or
            supporting documentation to be furnished by the Requesting Authority
            before we can provide the relevant customer information requested.
            Such documents may include a court order, subpoena, search warrant,
            authorization document(s) and/or other valid legal process, as the
            case may be.
          </p>
          <p className="policy_text px-4 py-1">
            As a matter of practice, we do not retain all user information
            and/or content, which may be deleted, de-personalized and/or revised
            from time to time. Certain customer information and customer content
            may not be retained at all or if retained, may not be retained for
            prolonged periods and/or in a comprehensive manner. Some of our
            customer information and customer content are regularly overwritten,
            de-personalized and/or discarded.
          </p>
          <p className="policy_text px-4 py-1">
            FROM TIME TO TIME, WE RECEIVE REQUESTS FOR DISCLOSURE OF DATA WHICH
            DO NOT BELONG TO AND/OR ARE NOT HELD BY ANONYDOXX US BUT A SEPARATE
            ENTITY. PLEASE NOTE THAT WHERE DATA AND/OR INFORMATION ARE NOT
            MAINTAINED BY AND/OR DOES NOT FALL WITHIN THE PURVIEW OF ANONYDOXX
            US, WE WILL NOT BE ABLE TO RENDER ASSISTANCE ON SUCH REQUESTS. THE
            RESPONSIBILITY IS ON THE REQUESTING AUTHORITY TO MAKE THE
            APPROPRIATE REQUEST(S) TO SUCH OTHER ENTITIES AND/OR AFFILIATES AS
            NECESSARY.
          </p>
          <p className="policy_text px-4 py-1">
            <b>WHAT KINDS OF REQUESTS DO WE RESPOND TO?</b>
          </p>
          <p className="policy_text px-4 py-1">
            We respond to the following types of Requests:
          </p>
          <h5 className="p-3">
            <u>Preservation Requests</u>
          </h5>
          <p className="policy_text px-4 py-1">
            We accept Requests from Requesting Authorities to preserve specified
            records or data, pending the issuance of valid legal process, for a
            reasonable period of time and in accordance with applicable laws and
            regulations. If we do not receive a formal legal request for the
            preserved information before the end of the preservation period, the
            preserved information may be deleted when the preservation period
            expires.
          </p>
          <p className="policy_text px-4 py-1">
            In addition to the information described below in the “Form of the
            Request” section below, preservation requests must be sent on law
            enforcement letterhead, signed and must include a statement with
            details about steps that are being taken to obtain legal process for
            the data sought to be preserved. In specific cases, we may need
            further supporting and/or legal documentation before we can respond
            to a Preservation Request. All our rights in this regard are fully
            and expressly reserved.
          </p>
          <h5 className="p-3">
            <u>Emergency Requests</u>
          </h5>
          <p className="policy_text px-4 py-1">
            We accept Requests from Requesting Authorities that give us a good
            faith belief regarding an imminent emergency involving risk of
            death, serious physical injury to a person or any risk of harm to a
            child. Please note that we evaluate emergency requests on a
            case-by-case basis in compliance with applicable laws and
            regulation. Where appropriate or necessary, we may require the
            Requesting Authority to furnish relevant legal and/or supporting
            documentation confirming their authorization and/or legal basis for
            making the request, without unnecessarily jeopardizing or delaying
            our response(s) to the requests.
          </p>
          <h5 className="p-3">
            <u>Legal Process Requests</u>
          </h5>
          <p className="policy_text px-4 py-1">
            We accept Requests made in accordance with applicable laws and
            regulations. This may include Requests arising from valid search
            warrants and subpoenas and other valid legal process, or valid
            Requests made in accordance with applicable mutual legal assistance
            arrangements and channels. Where appropriate or necessary, we may
            require the Requesting Authority to furnish relevant legal and/or
            supporting documentation confirming their authorization and/or legal
            basis for making the request.
          </p>
          <p className="policy_text px-4 py-1">
            <b> RETAINING AND DISCLOSING OF DATA </b>
          </p>
          <p className="policy_text px-4 py-1">
            We will only retain and disclose data in accordance with our
            <b>
              {" "}
              <u>Terms of Service</u>
            </b>{" "}
            (including our{" "}
            <b>
              {" "}
              <u>Privacy Policy</u>
            </b>
            ), subject always to applicable laws and regulations.
          </p>
          <p className="policy_text px-4 py-1">
            <b> FORM OF REQUEST</b>
          </p>
          <p className="policy_text px-4 py-1">
            We will only respond to written Requests that are made in accordance
            with applicable laws and regulations. In connection with any
            Request, please clearly specify the following:
          </p>
          <ul className="px-5">
            <li style={{ listStyleType: "disc" }}>the Requesting Authority;</li>
            <li style={{ listStyleType: "disc" }}>
              the identity of the specific officer and/or agent of the
              Requesting Authority responsible for the request (the “
              <b>Request Contact</b>”) and his or her rank, badge/identification
              number and identification documents;
            </li>
            <li style={{ listStyleType: "disc" }}>
              the relevant authorization document(s) of the specific officer
              and/or agent of the Requesting Authority, if applicable;
            </li>
            <li style={{ listStyleType: "disc" }}>
              contact details for the Request Contact, including phone number,
              email address (which must be from the Requesting Authority’s email
              domain address) and postal address;
            </li>
            <li style={{ listStyleType: "disc" }}>a Request response date;</li>
            <li style={{ listStyleType: "disc" }}>
              the AnonyDoxx user to which the Request relates (including users’
              identification / account details);
            </li>
            <li style={{ listStyleType: "disc" }}>
              the specific data and/or documents being requested from us
              regarding the relevant user/account and its relationship to the
              investigation to which the Request relates; and
            </li>
            <li style={{ listStyleType: "disc" }}>
              the basis of the Request, including the provisions of any specific
              legislation being relied upon and, where applicable and/or
              necessary, details of the nature of the investigation, procedure
              and/or process being carried out by the Requesting Authority.
            </li>
          </ul>
          <p className="policy_text px-4 py-1">
            Please note that we may be unable to respond to any vague or
            incomplete Requests.
          </p>
          <p className="policy_text px-4 py-1">
            <b>NOTIFICATION OF OUR USERS</b>
          </p>
          <p className="policy_text px-4 py-1">
            We respect our users’ rights and privacy. We may therefore notify
            the relevant user about any Requests for their data prior to
            disclosure, unless we are prohibited from doing so under applicable
            laws or regulations, by the terms of any legal process (such as a
            confidentiality order) or where we reasonably believe that such
            disclosure may create imminent and/or serious danger or risk for us
            or any third party.
          </p>
          <p className="policy_text px-4 py-1">
            Law enforcement officials who believe that notification would
            jeopardize a law enforcement investigation should obtain an
            appropriate court order or legal process that specifically prohibits
            member notification. It is the law enforcement official’s
            responsibility to request that we do not notify a customer of your
            request because it would jeopardize a law enforcement investigation.
            We will carefully consider your request and our compliance with the
            same will be subject to applicable laws or regulations. All of our
            rights in this regard are expressly reserved.
          </p>
          <p className="policy_text px-4 py-1">
            If your data request draws attention to an ongoing or prior
            violation of our{" "}
            <b>
              <u>Terms of Service</u>
            </b>{" "}
            or our{" "}
            <b>
              <u>Acceptable Use Policy</u>
            </b>
            , we will take action to prevent further abuse, including actions
            that may notify the user that we are aware of their misconduct. If
            you believe in good faith that such actions will jeopardize an
            ongoing investigation, it is your responsibility to request that we
            defer such action, which includes but is not limited to your
            providing the appropriate court order or legal process prohibiting
            such notification. We will carefully consider your request and our
            compliance with the same will be subject to applicable laws or
            regulations. All our rights in this regard are expressly reserved.
          </p>
          <b>REIMBURSEMENT OF COSTS</b>
          <p className="policy_text px-4 py-1">
            To the extent permitted by applicable laws and regulations, we may
            seek reimbursement of our costs in responding to a Request.
          </p>
          <b>TESTIMONY SUPPORT</b>
          <p className="policy_text px-4 py-1">
            We do not provide expert testimony support, except to the extent
            required by applicable laws and regulations.
          </p>
          <b>WHERE SHOULD REQUESTS BE SENT TO?</b>
          <p className="policy_text px-4 py-1">
            All Requests should be sent to security@anonydoxx.io with “Law
            Enforcement Disclosure Request” or “Governmental Authority
            Disclosure Request”, as applicable, in the subject line.
          </p>
          <p className="policy_text px-4 py-1">Please note that:</p>
          <ul className="px-5">
            <li style={{ listStyleType: "disc" }}>
              we may take longer to respond to any Requests not sent to the
              above contact details;
            </li>
            <li style={{ listStyleType: "disc" }}>
              we will not review correspondences sent by anyone other than law
              enforcement authorities to the above contact details; and
            </li>
            <li style={{ listStyleType: "disc" }}>
              if we accept any legal processes via the above contact details,
              such acceptance is for convenience only and does not waive any of
              our rights or objections, including for lack of proper service or
              jurisdiction.
            </li>
          </ul>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default SecTerms;
