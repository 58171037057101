import {
  HomeDemo3IcoPlatforms1,
  HomeDemo3IcoPlatforms2,
  HomeDemo3IcoPlatforms3,
  HomeDemo3IcoPlatforms4,
  HomeDemo3IcoPlatforms5,
  HomeDemo3IcoPlatforms6,
  HomeDemo3Services1,
  HomeDemo3Services2,
  HomeDemo3Services3,
  HomeDemo3Services4,
  HomeDemo3Services5,
  HomeDemo3Services6,
  HomeDemo3TokenIcon1,
  HomeDemo3TeamImg1,
  HomeDemo3TeamImg2,
  HomeDemo3TeamImg3,
  HomeDemo3TeamImg4,
  HomeDemo3Partners1,
  HomeDemo3Partners2,
  HomeDemo3Partners3,
  HomeDemo3Partners4,
  HomeDemo3Partners5,
  HomeDemo3Partners6,
  HomeDemo3Partners7,
  HomeDemo3Partners8,
  HomeDemo3Partners9,
  HomeDemo3Partners10,
  HomeDemo3Partners11,
  HomeDemo3Partners12,
  HomeDemo3RoadmapIcons1,
  HomeDemo3RoadmapIcons2,
  HomeDemo3RoadmapIcons3,
  HomeDemo3RoadmapIcons4,
  HomeDemo3RoadmapIcons5,
  HomeDemo3RoadmapIcons6,
  HomeDemo3RoadmapIcons7,
  HomeDemo3RoadmapIcons8,
  HomeDemo3RoadmapIcons9,
  HomeDemo3BlogImg1,
  HomeDemo3BlogImg2,
  HomeDemo3BlogImg3,
} from "../../utils/allImgs";

export const VerticalSocial = [
  { nameIco: "fa fa-telegram", url: "https://t.me/AnonyDoxx" },
  // {nameIco:'fa fa-medium'},
  // {nameIco:'fa fa-facebook'},
  { nameIco: "fa fa-twitter", url: "https://mobile.twitter.com/AnonyDoxx" },
  // {nameIco:'fa fa-github'},
  // {nameIco:'fa fa-instagram'},
  // {nameIco:'fa fa-linkedin'}
];

export const HowItWorksInfo = [
  { step: 1, title: "Powerfull Mobile and Online App" },
  { step: 2, title: "Brings more Transparency and Speed" },
  { step: 3, title: "Special for Multiple Use Capabilities" },
];

export const SingleCoolFact = [
  {
    img: HomeDemo3IcoPlatforms1,
    ico_check: "Pablo's Hippos",
    url: "https://www.pabloshipposnft.com",
  },
  {
    img: HomeDemo3IcoPlatforms2,
    ico_check: "Shield Coin",
    url: "https://theshieldcoin.com",
  },
  {
    img: HomeDemo3IcoPlatforms3,
    ico_check: "Vault Finance",
    url: "https://Thevaultfinance.com",
  },
  // {
  //     img:HomeDemo3IcoPlatforms4,
  //     ico_check:true
  // },
  // {
  //     img:HomeDemo3IcoPlatforms5,
  //     ico_check:false
  // },
  // {
  //     img:HomeDemo3IcoPlatforms6,
  //     ico_check:true
  // }
];

export const service_single_content = [
  {
    img: HomeDemo3Services1,
    title: "Smart Trading Modules",
  },
  {
    img: HomeDemo3Services2,
    title: "Adaptive Social Assistant",
  },
  {
    img: HomeDemo3Services3,
    title: "Analyzer of the News ",
  },
  {
    img: HomeDemo3Services4,
    title: "Exchange Order Management",
  },
  {
    img: HomeDemo3Services5,
    title: "Module of Price Notification",
  },
  {
    img: HomeDemo3Services6,
    title: "Crypto Trading Platform",
  },
];

export const timelineInfo = [
  {
    left: "10%",
    ClassName: "data_cir cir_0",
    date: "Q2 2017",
    IsSpan: true,
    TextSpan: "Emergence and design of the idea",
    IsTowLi: false,
    TextTowLi1: "",
    TextTowLi2: "",
    IsThreeLi: false,
    TextThreeLi1: "",
    TextThreeLi2: "",
    TextThreeLi3: "",
    IsFourLi: false,
    TextFourLi1: "",
    TextFourLi2: "",
    TextFourLi3: "",
    TextFourLi4: "",
    img: HomeDemo3RoadmapIcons1,
  },
  {
    left: "20%",
    ClassName: "data_cir cir_1",
    date: "Q3 2017",
    IsSpan: false,
    TextSpan: "",
    IsTowLi: false,
    TextTowLi1: "",
    TextTowLi2: "",
    IsThreeLi: false,
    TextThreeLi1: "",
    TextThreeLi2: "",
    TextThreeLi3: "",
    IsFourLi: true,
    TextFourLi1: "built our team",
    TextFourLi2: "company establishment",
    TextFourLi3: "legal issues setting",
    TextFourLi4: " concept development",
    img: HomeDemo3RoadmapIcons2,
  },
  {
    left: "30%",
    ClassName: "data_cir cir_2",
    date: "Q4 2017",
    IsSpan: false,
    TextSpan: "",
    IsTowLi: true,
    TextTowLi1:
      "start development of the ealier version and crypto wallet version",
    TextTowLi2: "negotiations on partnerships",
    IsThreeLi: false,
    TextThreeLi1: "",
    TextThreeLi2: "",
    TextThreeLi3: "",
    IsFourLi: false,
    TextFourLi1: "",
    TextFourLi2: "",
    TextFourLi3: "",
    TextFourLi4: "",
    img: HomeDemo3RoadmapIcons3,
  },
  {
    left: "40%",
    ClassName: "data_cir cir_3",
    date: "Q1 2018",
    IsSpan: false,
    TextSpan: "",
    IsTowLi: false,
    TextTowLi1: "",
    TextTowLi2: "",
    IsThreeLi: true,
    TextThreeLi1: "website launch",
    TextThreeLi2: "White Paper published",
    TextThreeLi3: "Private and Pre-sale start",
    IsFourLi: false,
    TextFourLi1: "",
    TextFourLi2: "",
    TextFourLi3: "",
    TextFourLi4: "",
    img: HomeDemo3RoadmapIcons4,
  },
  {
    left: "50%",
    ClassName: "data_cir cir_4 red_c",
    date: "Q2 2018",
    IsSpan: false,
    TextSpan: "",
    IsTowLi: false,
    TextTowLi1: "",
    TextTowLi2: "",
    IsThreeLi: true,
    TextThreeLi1: "Crowdsale",
    TextThreeLi2: "marketing campaign",
    TextThreeLi3: "listing to the major exchanges",
    IsFourLi: false,
    TextFourLi1: "",
    TextFourLi2: "",
    TextFourLi3: "",
    TextFourLi4: "",
    img: HomeDemo3RoadmapIcons5,
  },
  {
    left: "60%",
    ClassName: "data_cir cir_5 next",
    date: "Q3 2018",
    IsSpan: false,
    TextSpan: "",
    IsTowLi: false,
    TextTowLi1: "",
    TextTowLi2: "",
    IsThreeLi: true,
    TextThreeLi1: "Crypto Wallet version release",
    TextThreeLi2: "Testnet launch",
    TextThreeLi3: " Platform ealier version development",
    IsFourLi: false,
    TextFourLi1: "",
    TextFourLi2: "",
    TextFourLi3: "",
    TextFourLi4: "",
    img: HomeDemo3RoadmapIcons6,
  },
  {
    left: "70%",
    ClassName: "data_cir cir_6 next",
    date: "Q4 2018",
    IsSpan: false,
    TextSpan: "",
    IsTowLi: false,
    TextTowLi1: "",
    TextTowLi2: "",
    IsThreeLi: true,
    TextThreeLi1: "Mobile App",
    TextThreeLi2: "Explorer",
    TextThreeLi3: "new partnerships and new marketplace support",
    IsFourLi: false,
    TextFourLi1: "",
    TextFourLi2: "",
    TextFourLi3: "",
    TextFourLi4: "",
    img: HomeDemo3RoadmapIcons7,
  },
  {
    left: "80%",
    ClassName: "data_cir cir_7 next",
    date: "Q1 2019",
    IsSpan: false,
    TextSpan: "",
    IsTowLi: false,
    TextTowLi1: "",
    TextTowLi2: "",
    IsThreeLi: true,
    TextThreeLi1: "Mainnet launch",
    TextThreeLi2: "Wallet beta-version release",
    TextThreeLi3: "Masternodes implementation",
    IsFourLi: false,
    TextFourLi1: "",
    TextFourLi2: "",
    TextFourLi3: "",
    TextFourLi4: "",
    img: HomeDemo3RoadmapIcons8,
  },
  {
    left: "90%",
    ClassName: "data_cir cir_8 next",
    date: "Q2 2019",
    IsSpan: false,
    TextSpan: "",
    IsTowLi: true,
    TextTowLi1: "Platform integration to electronic marketplaces",
    TextTowLi2: "Zero Fee",
    IsThreeLi: false,
    TextThreeLi1: "",
    TextThreeLi2: "",
    TextThreeLi3: "",
    IsFourLi: false,
    TextFourLi1: "",
    TextFourLi2: "",
    TextFourLi3: "",
    TextFourLi4: "",
    img: HomeDemo3RoadmapIcons9,
  },
];

export const ServiceBlock = [
  {
    classIco: "icon ti-mobile",
    title: "Powerfull Mobile and Online App",
  },
  {
    classIco: "icon ti-widget",
    title: "Brings more Transparency and Speed",
  },
  {
    classIco: "icon ti-settings",
    title: "Special for Multiple Use Capabilities",
  },
];

export const SocialListIco = [
  { icoName: "fa fa-facebook-square" },
  { icoName: "fa fa-twitter-square" },
  { icoName: "fa fa-github-square" },
  { icoName: "fa fa-instagram" },
  { icoName: "fa fa-medium" },
];

export const FQAInfo = [
  {
    text: "What are the objectives of this token?",
    ID: "Q1",
  },
  {
    text: "What is Token Sale and pre-sale?",
    ID: "Q2",
  },
  {
    text: "What is the pre-sale start date?",
    ID: "Q3",
  },
  {
    text: "how may I take part in pre-sale?",
    ID: "Q4",
  },
];

export const DocElementTitle = [
  {
    title: "WhitePaper",
  },
  {
    title: "OnePager",
  },
  {
    title: "Rating Review",
  },
  {
    title: "Marketing Plan",
  },
];

export const TokenText = [
  {
    text: "Lorem ipsum dolor sit amet, conse ctetur elit",
    img: HomeDemo3TokenIcon1,
  },
  {
    text: "Sed quis accumsan nisi Ut ut felis",
    img: HomeDemo3TokenIcon1,
  },
  {
    text: "felis congue nisl hendrerit commodo",
    img: HomeDemo3TokenIcon1,
  },
  {
    text: "arch nemo sequi rem saepe ad quasi ullam.",
    img: HomeDemo3TokenIcon1,
  },
];

export const MissionData = [
  {
    icoName: "ti-shine",
    title: "Quality",
  },
  {
    icoName: "ti-ruler-pencil",
    title: "Creativity",
  },
  {
    icoName: "ti-heart",
    title: "Reliability",
  },
];

export const TeamMember = [
  {
    img: HomeDemo3TeamImg1,
    title: "Sunny khan",
    text: "Executive Officer",
  },
  {
    img: HomeDemo3TeamImg2,
    title: "Ajoy Das",
    text: "Business Development",
  },
  {
    img: HomeDemo3TeamImg3,
    title: "Afroza Mou",
    text: "UX/UI Designer",
  },
  {
    img: HomeDemo3TeamImg4,
    title: "Lim Sarah",
    text: "Head of Marketing",
  },
  {
    img: HomeDemo3TeamImg1,
    title: "Sunny khan",
    text: "Executive Officer",
  },
  {
    img: HomeDemo3TeamImg2,
    title: "Ajoy Das",
    text: "Business Development",
  },
  {
    img: HomeDemo3TeamImg3,
    title: "Afroza Mou",
    text: "UX/UI Designer",
  },
  {
    img: HomeDemo3TeamImg4,
    title: "Lim Sarah",
    text: "Head of Marketing",
  },
];

export const PartnersData = [
  //   { img: HomeDemo3Partners1 },
  //   { img: HomeDemo3Partners2 },
  //   { img: HomeDemo3Partners3 },
  { img: HomeDemo3Partners4 },
  { img: HomeDemo3Partners5 },
  { img: HomeDemo3Partners6 },
  { img: HomeDemo3Partners7 },
  { img: HomeDemo3Partners8 },
  { img: HomeDemo3Partners9 },
  { img: HomeDemo3Partners10 },
  { img: HomeDemo3Partners11 },
  //   { img: HomeDemo3Partners12 },
];

export const BlogPost = [
  { img: HomeDemo3BlogImg1 },
  { img: HomeDemo3BlogImg2 },
  { img: HomeDemo3BlogImg3 },
];
