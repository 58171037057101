import Header from "../../layouts/Header";
import Footer from "../../layouts/FooterPages";
const SecPolicy = ({ data }) => {
  return (
    <>
      <Header />
      <section className="trust-section section-padding not-copy section-margin-100 p-3" style = {{margin:"auto", maxWidth : "1024px"}} >
        <div className="section-heading ">
          <h2
            className="text-center" style = {{color : "#FFFFFF"}}
          >
            PRIVACY POLICY
          </h2>
          <h6  className="text-center" style = {{color : "#FFFFFF"}}>
            Last updated: June 22, 2022
          </h6>
          <p
            className = "policy_text py-3"
          >
            The privacy of your data – and it is your data, not ours! – is a big
            deal to us. We are committed to the protection of any personal
            information we obtain from you. This policy applies to all products
            and services provided by AnonyDoxx, LLC (“AnonyDoxx”).
            In this policy, we specify (i) what data we collect, use and share,
            and why we do so, (ii) how your data is handled, including with whom
            and under what circumstances we may share it and (iii) your rights
            with respect to your data and how to exercise those rights. <br />
            We promise we will never sell your data: never have, never will.{" "}
          </p>
          <h5  className = "policy_text py-3">
            <u> I. Data We Collect and How We Collect It</u>
          </h5>
          <h6  className="policy_text pl-4  py-1">
            A. Data You Provide to Us
          </h6>
          <p  className="policy_text pl-4  py-1">
            The types of personal data we collect directly from you varies based
            on how you interact with us and our services, including when you
            register, connect your wallet and/or use our Vault Verification
            protocol. The types of data we collect may include:{" "}
          </p>
          <ul  className="pl-5">
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              {" "}
              contact details, such as your legal name, current address, email
              address, phone number and other electronic identifiers;
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              {" "}
              account login credentials, such as usernames and passwords,
              password hints and similar security information;
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              {" "}
              government issued identification, such as passport, driver’s
              license, birth certificate or other identifying information;{" "}
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              {" "}
              photo and video recordings, such as when you use our Vault
              Verification protocol;
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              Biometric Data, including facial recognition processing (see the
              below section, “Biometric Privacy”);
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              billing or financial information, including payment card
              information;
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              voluntary correspondence (when you write AnonyDoxx with a question
              or to ask for help, we keep that correspondence, including the
              email address, so that we have a history of past correspondences
              to reference if you reach out in the future);
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              information about your choices, such as communication preferences
              and other consents.
            </li>
          </ul>
          <h6  className="policy_text pl-4  py-1">
            B. Data From Other Sources
          </h6>
          <p  className="policy_text pl-4  py-1">
            We may obtain information about you from other sources, including if
            you connect or link a profile or wallet from a third party, we may
            obtain certain information that identifies you. We may also obtain
            data from publicly-available sources and/or data suppliers from
            which we obtain data to validate or supplement the information we
            hold.
          </p>
          <h6  className="policy_text pl-4 py-1">
            C. Data We Collect Automatically
          </h6>
          <p  className="policy_text pl-4  py-1">
            When you browse our website/application(s), your browser or device
            automatically shares certain information such as your IP address,
            information about how you interact with our website/application(s),
            and which operating system and browser version you are using. We may
            collect this information through tracking technologies like cookies
            (for further information regarding cookies, see Section VII below).
          </p>
          <h5  className = "policy_text py-3">
            <u> II. How we Use and Share Your Information</u>
          </h5>
          <h6  className="policy_text pl-4  py-1">
            A. Vault Verification
          </h6>
          <p  className="policy_text pl-4  py-1">
            The data identified above is processed to provide our Vault
            Verification service, a form of identity check whereby we
          </p>
          <ol  className="pl-5">
            <li style={{ listStyleType: "decimal" ,color : "#FFFFFF"}}>
              {" "}
              analyze your government issued identification against an image
              capture of your face (a “selfie”) using our facial recognition
              technology;
            </li>
            <li style={{ listStyleType: "decimal" ,color : "#FFFFFF"}}>
              {" "}
              initiate a background check to which you must consent;
            </li>
            <li style={{ listStyleType: "decimal" ,color : "#FFFFFF"}}>
              {" "}
              collect a video attestation from you;
            </li>
            <li style={{ listStyleType: "decimal" ,color : "#FFFFFF"}}>
              {" "}
              review a self-recorded, video attestation and confirm the
              identification made via automated processing (no identity
              verification is completed without human review by AnonyDoxx); and
            </li>
            <li style={{ listStyleType: "decimal" ,color : "#FFFFFF"}}>
              issue a blockchain ID certifying your AnonyDoxx Vault
              Verification.
            </li>
          </ol>
          <p  className="policy_text pl-4  py-1">
            Biometric Data is stored only so long as is needed conduct step (1),
            above.
            <br />
            Certain data (e.g., your image and certain identification materials)
            will be securely stored by AnonyDoxx for the duration of your
            verification (AnonyDoxx Vault Verification expires after one year).
            <br />
            Your blockchain certificate is a unique, personal identifier. Your
            information will be stored in an immutable chain as part of a
            database called a distributed ledger, and may not later be changed
            or deleted.
            <br />
            <i>
              Please carefully consider how the use of blockchain may limit the
              exercise of certain of your rights regarding personal data, as
              discussed in “Your Rights with Respect to Your Information”
              (Section III).
            </i>
          </p>
          <h6  className="policy_text pl-4  py-1">
            B. Using Your Data
          </h6>
          <p  className="policy_text pl-4  py-1">
            We and third parties working on our behalf may use the information
            we collect about you for various purposes, including:
          </p>
          <ol  className="pl-5">
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              {" "}
              to provide you with services you requested (i.e., Vault
              Verification; see also “Biometric Privacy” (Section IV) for
              further details);
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              {" "}
              to run detailed background and identity verifications and
              validations globally;
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              {" "}
              to verify that identification issued to AnonyDoxx matches local
              government databases;
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              {" "}
              to verify organizational documents filed with the local state
              governing authority, business licenses, active status of business,
              etc.;
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>to manage your account;</li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              to respond to your requests or to communicate with you;
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              to send email communications and/or marketing materials that may
              be of interest to you; and
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              to improve and enhance the functionality of our website(s) and
              services.
            </li>
          </ol>
          <h6  className="policy_text pl-4  py-1">
            C. Sharing Your Data
          </h6>
          <p  className="policy_text pl-4  py-1">
            We may share your information with third parties as permitted or
            required by law, including:
          </p>
          <ol  className="pl-5">
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              {" "}
              to fulfill the purposes listed above;
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              {" "}
              to share with our service providers, suppliers, agents and
              representatives that assist in providing the services;
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              {" "}
              if we are involved in a merger, acquisition, sale of our business,
              bankruptcy or other similar event, we may provide data to
              individuals or entities involved in such an event;
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              {" "}
              to comply with legal obligations, including to meet any applicable
              law, regulation, legal process or other requirement;
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              to detect investigate and help prevent security, fraud or
              technical issues;
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              to respond to your requests or to communicate with you;
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              to protect and enforce our rights, interests, property or safety
              or the safety of our users, employees or others; and
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              any other sharing or disclosure with your consent.
            </li>
          </ol>
          <h5  className = "policy_text py-3">
            <u>III. Your Rights With Respect to Your Information</u>
          </h5>
          <h6  className="policy_text pl-4  py-1">
            A. Regulatory Matters
          </h6>
          <p  className="policy_text pl-4  py-1">
            At AnonyDoxx, we apply the same data rights to all customers,
            regardless of their location. Currently, some of the most
            privacy-forward regulations in place are the European Union’s (“EU”)
            General Data Protection Regulation (“GDPR”) and California Consumer
            Privacy Act (“CCPA”) in the United States (“U.S.”). AnonyDoxx
            recognizes all of the rights granted in these regulations, except as
            limited by applicable law.
            <br />
            These rights include:
          </p>
          <ol  className="pl-5">
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              {" "}
              <b>Right to Know.</b> You have the right to know what personal
              information is collected, used, shared or sold by us. We outline
              both the categories and specific bits of data we collect, as well
              as how they are used, in this privacy policy.
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              {" "}
              <b>Right of Access.</b> This includes your right to access the
              personal information we gather about you and your right to obtain
              information about the sharing, storage, security and processing of
              that information.
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              {" "}
              <b>Right to Correction.</b> You have the right to request
              correction of your personal information.
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              {" "}
              <b>Right to Erasure / “To Be Forgotten”.</b> This is your right to
              request, subject to certain limitations under applicable law, that
              your personal information be erased from our possession and, by
              extension, all of our service providers. Fulfillment of some data
              deletion requests may prevent you from using AnonyDoxx services
              because our applications may then no longer work. In such cases, a
              data deletion request may result in closing your account.
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              <b>Right to Complain.</b> You have the right to make a complaint
              regarding our handling of your personal information with the
              appropriate supervisory authority. EU individuals should go to
              &nbsp;
              <a href="https://edpb.europa.eu/about-edpb/board/members_en">
                https://edpb.europa.eu/about-edpb/board/members_en
              </a>{" "}
              to identify your specific authority or find out more about this
              right.{" "}
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              <b>Right to Restrict Processing.</b> This is your right to request
              restrictions of how and why your personal information is used or
              processed, including opting out of sale of personal information
              (again, we never have and never will sell your personal data). You
              have the right to withdraw consent at any time. Where consent
              forms the sole basis of our processing, we will promptly cease all
              processing of such data.
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              <b>Right to Object.</b> You have the right, in certain situations,
              to object to how or why your personal information is processed.
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              <b>Right to Portability.</b> You have the right to receive the
              personal information we have about you and the right to transmit
              it to another party.
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              <b>Right Not to be Subject to Automated Decision-Making.</b> You
              have the right to object and prevent any decision that could have
              a legal, or similarly significant, effect on you from being made
              solely based on automated processes. This right is limited,
              however, if the decision is necessary for performance of any
              contract between you and us, is allowed by applicable law or is
              based on your explicit consent. (Again, our Vault Verification
              procedure includes human review and is not based purely on
              automated decision-making processes.)
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              <b>Right to Non-Discrimination.</b> This right stems from the
              CCPA. We do not and will not charge you a different amount to use
              our services, offer you different discounts or give you a lower
              level of customer service because you have exercised your data
              privacy rights. However, the exercise of certain rights (such as
              the right “to be forgotten”) may, by virtue of your exercising
              those rights, prevent you from using our services.
            </li>
          </ol>
          <p  className="policy_text pl-4  py-1">
            Please note, you have the right at any time to withdraw a
            previously-provided consent. We will promptly honor such requests;
            however, withdrawing consent will not affect the lawfulness of any
            authorized processing which precedes the withdrawal of your consent.
          </p>
          <p  className="policy_text pl-4  py-1">
            If you are in the EU, you can identify your specific authority to
            file a complaint or find out more about GDPR, at &nbsp;
            <a
              href="#"
              onClick={() =>
                window.open(
                  "https://edpb.europa.eu/about-edpb/board/members_en",
                  "_blank"
                )
              }
            >
              https://edpb.europa.eu/about-edpb/board/members_en.
            </a>
          </p>
          <h6  className="policy_text pl-4  py-1">
            B. Marketing
          </h6>
          <p  className="policy_text pl-4  py-1">
            AnonyDoxx may send you information about products and services of
            ours or our partners that we think you may like. If you have agreed
            to receive marketing, you may always opt out by following the
            unsubscribe link in each marketing email or by contacting us
            directly at security@anonydoxx.io.
          </p>
          <h5  className = "policy_text py-3">
            IV. Biometric Privacy
          </h5>
          <p  className="policy_text pl-4  py-1">
            AnonyDoxx collects and processes Biometric Data, including facial
            scans, to provide its services, as outlined in Section II.A (Vault
            Verification), above. AnonyDoxx collects such information based on
            the written consent of its users, and we will share such data with
            our service provider(s) solely to provide requested services.
          </p>
          <p  className="policy_text pl-4  py-1">
            Like all data we collect, we will never sell Biometric Data.
          </p>
          <h6  className="policy_text pl-4  py-1">
            A. Biometric Data Defined
          </h6>
          <p  className="policy_text pl-4  py-1">
            We base our practices with respect to biometrics on what we assess
            to be the most stringent requirements for biometric privacy, which
            are laid out in the Illinois Biometric Information Privacy Act, 740
            ILCS § 14/1, et seq. (“BIPA”). As used in this policy, the term
            Biometric Data means “biometric data”, “biometric identifiers,” and
            “biometric information” as defined in BIPA:
          </p>
          <ol  className="pl-5">
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              {" "}
              “Biometric identifier” means a retina or iris scan, fingerprint,
              voiceprint or scan of hand or face geometric. Biometric
              identifiers do not include writing samples, written signatures,
              photographs, human biological samples used for valid scientific
              testing or screening, demographic data, tattoo descriptions or
              physical descriptions such as height, weight, hair color or eye
              color.
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              {" "}
              “Biometric information” means any information, regardless of how
              it is captured, converted, stored or shared, based on an
              individual’s biometric identifier used to identify an individual.
              Biometric information does not include information derived from
              items or procedures excluded under the definition of biometric
              identifiers.
            </li>
          </ol>
          <p  className="policy_text pl-4  py-1">
            For clarity, Biometric Data also includes any similar state or local
            law definitions related to any biological characteristic of a
            person, or information based upon such a characteristic, including
            but not limited to, “biometric identifier” as defined under the
            Texas Biometric Privacy Law, Tex. Bus. & Com Code § 503.001,
            “biometric information” as defined in the Washington Biometric
            Privacy Law, Wash. Rev. Code Ann. § 19.375.020, “biometric
            information” as used in the CCPA, and “biometric information” as
            used in the New York Stop Hacks and Improve Electronic Data and
            Security Act (NY SHIELD Act), N.Y. Gen Bus. Law § 899-bb.
          </p>
          <h6  className="policy_text pl-4  py-1">
            B. Purpose for Collection of Biometric Data
          </h6>
          <p  className="policy_text pl-4  py-1">
            Our Vault Verification creates Biometric Data (face scans) for the
            purpose of identifying you and issuing a certification via
            blockchain. Our facial recognition technology compares your
            “selfie”, government-issued identification, and self-recorded, video
            attestation. This process is reviewed by AnonyDoxx staff prior to
            issuing the blockchain Vault Verification. (The process is detailed
            above at “Vault Verification” (Section II.A).)
          </p>
          <h6  className="policy_text pl-4  py-1">
            C. Authorization
          </h6>
          <p  className="policy_text pl-4  py-1">
            Prior to receiving Biometric Data, we must:
          </p>
          <ol  className="pl-5">
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              {" "}
              inform the subject in writing that AnonyDoxx and/or its service
              providers are collecting, capturing or otherwise obtaining
              Biometric Data and that AnonyDoxx may provide such biometric data
              to its service providers;
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              {" "}
              inform the subject in writing of the specific purpose and length
              of time for which the subject’s biometric data is being collected,
              stored and used; and
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              {" "}
              receive a written authorization, signed by the subject (or the
              subject’s authorized representative), consenting to the
              collection, storage, and use of the subject’s Biometric Data for
              the specific purposes described in this policy and for AnonyDoxx
              to provide such Biometric Data to its vendor(s).
            </li>
          </ol>
          <h6  className="policy_text pl-4  py-1">
            D. Disclosure
          </h6>
          <p  className="policy_text pl-4  py-1">
            AnonyDoxx will not disclose or disseminate any Biometric Data to
            anyone other than its authorized vendor(s) without/unless:
          </p>
          <ol  className="pl-5">
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              {" "}
              the subject of the Biometric Data (or the subject’s authorized
              representative) consents to the disclosure or dissemination;
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              {" "}
              the disclosure or dissemination completes a financial transaction
              requested or authorized by the subject (or the subject’s
              authorized representative) of the Biometric Data;
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              {" "}
              the disclosure or dissemination is required by state or federal
              law or municipal ordinance; or
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              {" "}
              the disclosure is required pursuant to a valid warrant or subpoena
              issued by a court of competent jurisdiction.
            </li>
          </ol>
          <h6  className="policy_text pl-4  py-1">
            E. Biometric Data Retention Schedule
          </h6>
          <p  className="policy_text pl-4  py-1">
            As a general matter, we will retain biometric information for only
            so long as is necessary to complete the initial identification
            (i.e., step (1) as described at Vault Verification (Section II.A)
            above), but in no instance longer than the first of:
          </p>
          <ol  className="pl-5">
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              {" "}
              AnonyDoxx receiving written notice from the subject (or the
              subject’s authorized representative) withdrawing the previously
              provided consent to process the subject’s Biometric Data; or
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              {" "}
              three (3) years following the last interaction between AnonyDoxx
              and the subject from whom the Biometric Data was collected.
            </li>
          </ol>
          <h6  className="policy_text pl-4  py-1">
            F. Data Storage
          </h6>
          <p  className="policy_text pl-4  py-1">
            AnonyDoxx will use, and will ensure its vendor(s) use, a reasonable
            standard of care to store, transmit and protect from disclosure any
            Biometric Data it collects or is collected on its behalf. Such
            storage, transmission and protection from disclosure will be
            performed in a manner that is the same as, or more protective than,
            the manner in which AnonyDoxx stores, transmits and protects from
            disclosure other confidential and sensitive information, including
            personal information.
          </p>
          <h5  className = "policy_text py-3">
            <u>V. Children’s Privacy</u>
          </h5>
          <p  className="policy_text pl-4  py-1">
            This website and our services are not intended for children under 13
            years of age, and we do not knowingly collect personal information
            from children under 13. If you are under 13, you are prohibited from
            providing any information through this website or our services.
          </p>
          <h5  className = "policy_text py-3">
            <u>
              VI. Transfer of Personal Information and Location of Processing
            </u>
          </h5>
          <p  className="policy_text pl-4  py-1">
            AnonyDoxx is based in the United States. As such, if you are located
            outside the U.S., your information may be transferred to or
            processed in the U.S., which may not offer the same level of
            protection to personal data as your home jurisdiction.
          </p>
          <h6  className="policy_text pl-4  py-1">
            A. Grounds for Processing
          </h6>
          <p  className="policy_text pl-4  py-1">
            When we collect personal information from within the European
            Economic Area, we do so:
          </p>
          <ol  className="pl-5">
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              {" "}
              where necessary to provide the service, fulfil a transaction or
              otherwise perform a contract with you or at your request prior to
              entering into a contract;
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              {" "}
              where we have a legitimate interest;
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              {" "}
              where necessary to comply with applicable law or legal obligation;
              and/or
            </li>
            <li style={{ listStyleType: "disc" ,color : "#FFFFFF"}}>
              {" "}
              where applicable, with your consent.
            </li>
          </ol>
          <h5  className = "policy_text py-3">
            <u>VII. Cookies</u>
          </h5>
          <h6  className="policy_text pl-4  py-1">
            A. First-Party Cookies
          </h6>
          <p  className="policy_text pl-4  py-1">
            We use only essential, persistent first-party cookies to store
            certain preferences, we make it easier for you to use our
            applications. A cookie is a piece of text stored by your browser to
            help it remember your login information, site preferences and more.
            You can adjust cookie retention settings in your own browser. Please
            note that if you restrict the placement of cookies on your device,
            certain areas of our services may be affected and/or not function as
            intended. To learn more about cookies, including how to view which
            cookies have been set and how to manage and delete them, please
            visit:{" "}
            <a href="https://www.allaboutcookies.org" target="_blank">
              www.allaboutcookies.org.
            </a>
          </p>
          <h6  className="policy_text pl-4  py-1">
            B. A Note on Do-Not-Track (DNT)
          </h6>
          <p  className="policy_text pl-4  py-1">
            Our website does not respond to DNT signals.
          </p>
          <h5  className = "policy_text py-3">
            <u>VIII. Security and Data Retention</u>
          </h5>
          <p  className="policy_text pl-4  py-1">
            We use reasonable measures to prevent unauthorized access, use or
            disclosure of your information. However, no method of transmission
            over the Internet is absolutely secure. Accordingly, we cannot
            guarantee the absolute security of your information. <br />
            Subject to any limitations or restrictions provided in law, we will
            maintain your information for as long as is reasonably necessary to
            fulfill the purposes of collection. We may retain your personal
            information for a longer period in the event of a complaint or if we
            reasonably believe there is a prospect of litigation.
          </p>
          <h5  className = "policy_text py-3">
            <u>IX. Third Party Links</u>
          </h5>
          <p  className="policy_text pl-4  py-1">
            This website may include links to third-party websites, plug-ins and
            application. Clicking on those links or enabling those connections
            may allow third parties to collect or share data about you. Such
            third party sites are not governed by this privacy policy. When you
            leave our website, we encourage you to read the privacy policy of
            every website you visit.
          </p>
          <h5  className = "policy_text py-3">
            <u>X. Modifications to this Privacy Policy</u>
          </h5>
          <p  className="policy_text pl-4  py-1">
            AnonyDoxx reserves the right to modify this privacy policy at any
            time by posting the revised version on this site and updating the
            last modified date at the top of this page.
          </p>
          <h5  className = "policy_text py-3">
            <u>XI. Contact Information</u>
          </h5>
          <p  className="policy_text pl-4  py-1">
            If you have any questions regarding this privacy policy, please
            contact us at security@anonydoxx.io.
          </p>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default SecPolicy;
