import React, { useState, useEffect } from "react";
import {
  addMailAddress,
  getAddressFromGSheet,
  saveAddressToGSheet,
} from "../../../../apis/tokenApi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Form = () => {
  const [mail, setMail] = useState("");
  const [count, setCount] = useState(0);

  const saveAddress = async () => {
    if (ValidateEmail(mail)) {
      let isoDateString = new Date().toISOString();
      const data = {
        address: mail,
      };
      const dataForGSheet = {
        Address: mail,
        Registered_at: isoDateString,
      };

      const response = await addMailAddress(data);
      if (response.status === "success") {
        let status = await saveAddressToGSheet(dataForGSheet);
        if (status) {
          notify("success", response.message);
        }
      } else if (response.status === "error") {
        if (response.message.code === 11000) {
          notify("error", "Your email is already registered.");
        }
      }
    }
    setMail("");
  };

  // Notification using React-toastify.
  const notify = (_type, _text) =>
    toast(_text, {
      position: "top-right",
      type: _type,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });

  function ValidateEmail(input) {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (input.match(validRegex)) {
      return true;
    } else {
      notify("error", "Please input the correct email address!");
      return false;
    }
  }

  const getAllAddress = async () => {
    let mails = await getAddressFromGSheet();
    if (mails.length > 0) {
      setCount(mails.length);
    }
  };

  useEffect(() => {
    getAllAddress();
  }, []);
  return (
    <div className="row justify-content-center">
      <div className="col-12 col-md-10 col-lg-8">
        <div className="contact_form">
          <form action="#" method="post" id="main_contact_form" noValidate>
            <div className="row">
              <div className="col-12">
                <div id="success_fail_info" />
              </div>
              <div className="col-12 col-md-12" data-aos="fade-up">
                <div className="group wow fadeInUp" data-wow-delay="0.3s">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    value={mail}
                    onChange={(e) => setMail(e.target.value)}
                    required
                  />
                  <span className="highlight" />
                  <span className="bar" />
                  <label>Enter your business email</label>
                </div>
              </div>
              <div
                className="col-12 text-center wow fadeInUp"
                data-wow-delay="0.6s"
              >
                <button
                  type="button"
                  className="btn dream-btn selected"
                  data-aos="fade-up"
                  onClick={saveAddress}
                >
                  Subscribe now
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Form;
